import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import "../components/style.css"

const IndexPage = () => (
  <Layout>
    <Seo title="Asociația de Vânătoare Valea Băii" />
    <StaticImage src="../images/valea-baii.png" />
    <p className="center">av@valea-baii.ro</p>
  </Layout>
)

export default IndexPage
